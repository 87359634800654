import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';

function App() {
  const [info, setInfo] = useState(null);
  const webcamRef = useRef(null);

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      await handleScan(imageSrc);
    } else {
      console.error('Failed to capture image');
    }
  };

  const handleScan = async (imageSrc) => {
    const blob = await fetch(imageSrc).then(res => res.blob());
    const formData = new FormData();
    formData.append('image', blob, 'captured-image.jpg');

    console.log('Uploading image...');
    fetch('http://localhost:3001/upload', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        console.log('Response from server:', data);
        setInfo(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1>Business Card Scanner</h1>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={640}
        height={480}
      />
      <button onClick={capture}>Capture</button>
      {info && (
        <div>
          <h2>Extracted Information:</h2>
          <p><strong>Name:</strong> {info.name}</p>
          <p><strong>Company:</strong> {info.company}</p>
          <p><strong>Job Title:</strong> {info.job_title}</p>
          <p><strong>Contact Info:</strong> {info.contact_info.join(', ')}</p>
        </div>
      )}
    </div>
  );
}

export default App;
